import { module } from 'modujs';
import 'jquery-validation/dist/jquery.validate.min';
import 'jquery-validation/dist/additional-methods.min';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                submitAjax: 'submitAjax',
                toggleStep: 'toggleStep',
                reset: 'reset',
            },
            change: {
                calcul: 'calcul',
            },
        }

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
        this.calcul();
    }

    submitAjax(e) {
        let module = this;
        let el = e.currentTarget;
        let form = $(el).parents('form');
        let error_container = document.getElementById('c-form_msg');
        this.checkForm(form);

        if(form.valid()) {
    
            $.ajax({
                url: this.ajaxurl,
                type: 'POST',
                data: form.serialize(),
                success: function (response) {

                    if (response.msg) {
                        form.find('.c-form_msg').addClass('u-margin-top-small');
                        form.find('.c-form_msg').html(response.msg);
                        form.find('.c-form_msg').show();
                        form.find('.c-form_msg').addClass('error');
                    }

                    if(response.status == 'success') {
                        form.find('.c-form_msg').removeClass('error');
                        form.find('.c-form_msg').hide();
                        if($('.b-adhesion_wrap').length) {
                            $('.b-adhesion_wrap').html(response.content);
                        }
                        module.call('scrollTo', '.b-adhesion_wrap', 'Scroll');
                    }
                },
            });
        }
    }

    toggleStep(e) {
        let module = this;
        let el = e.currentTarget;
        let form = $(el).parents('form');
        let step = el.getAttribute('data-step');
        let type = el.getAttribute('data-type');
        $.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: {'action': 'agChoosePayment', 'data': form.serialize(), 'type': type},
            success: function (response) {
                
                if($('.b-adhesion_wrap').length) {
                    $('.b-adhesion_wrap').html(response.content);
                }
                module.call('scrollTo', '.b-adhesion_wrap', 'Scroll');
            },
        });
    }    

    checkForm(form) {
        var $ = jQuery;
        var $msg_error = 'Ce champ est obligatoire';
        var $msg_error_email = 'Saisir une adresse email valide (ex: email@exemple.com)';
        var $msg_error_format = 'Format invalide';
        var msg_format = $msg_error_format;

        function verifierNumRne(numRne) {
            // Un code valide est toujours un string
            if (typeof (numRne) !== 'string') {
                return false;
            }

            // On supprime les espaces et on met tout en minuscules
            var numRnePropre = numRne.replace(/ /g, '').toLowerCase();

            // Un code valide a toujours une longueur de 8
            if (numRnePropre.length !== 8) {
                return false;
            }

            // alphabet pris en compte (23 lettres, sans I, O et Q)
            var alphabet23 = 'abcdefghjklmnprstuvwxyz'.split('');

            // On récupère les chiffres et les lettres dans des variables séparées
            var numRnePropreChiffres = numRnePropre.substr(0, 7);
            var numRnePropreLettre = numRnePropre.substr(7, 1);

            // Le rang de la lettre correspond au reste de la division par 23
            var rangCalcule = numRnePropreChiffres % 23;

            // On récupère la lettre calculée dans l'alphabet
            var lettreCalculee = alphabet23[rangCalcule];

            // On compare la lettre du code UAI à la lettre calculée et on retourne TRUE ou FALSE
            var validCode = numRnePropreLettre == lettreCalculee ? true : false;

            return validCode;
        }
    
        $.validator.addMethod(
            "rne", 
            function(value, element) {
                return verifierNumRne(value)
            },
            "Numéro RNE invalide"
        );

        $(form).validate({
          ignore: [],
          lang: 'fr',
          errorElement : 'div',
          errorPlacement: function(error, element) {
            var placement = $(element).data('error');
            if (placement) {
              $(placement).append(error);
            } else {
              error.insertAfter(element);
            }
          },
        });
        const required = this.$('required');
        const rules = [];
        // required.concat(this.$('calcul'));
        required.forEach(function(element) {
            if ($(element).data('error_msg_format')) {
                msg_format = $(element).data('error_msg_format');
            }
          if(!$(element).parents('.is-hidden').length) {
            $(element).rules('add', { required: true, messages : { required : $msg_error } });
            if ($(element).attr('type') == 'email') {
              $(element).rules('add', { email: true, messages : { email : $msg_error_email } });
            }
            
            if ($(element).data('type') == 'telephone') {
                $(element).rules('add', {
                pattern:
                    '^(?:(?:\\+|00)33[\\s.-]{0,3}(?:\\(0\\)[\\s.-]{0,3})?|0)[1-9](?:(?:[\\s.-]?\\d{2}){4}|\\d{2}(?:[\\s.-]?\\d{3}){2})$',
                messages: { pattern: msg_format },
                });
            }

            if ($(element).data('type') == 'rne') {
                $(element).rules('add', { rne: true });
            }

            if ($(element).data('type') == 'number') {
                $(element).rules('add', {
                digits: true,
                messages: { digits: msg.concat(' en chiffres') },
                });
            }

            if ($(element).data('type') == 'cp') {
                $(element).rules('add', {
                pattern: '^(([0-8][0-9])|(9[0-5])|(9[7-8]))[0-9]{3}$',
                messages: { pattern: msg_format },
                });
            }
          }
        });
    }

    reset() {
        $.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: {'action': 'reset'},
            success: function (response) {
                window.location.reload();
                // if($('.b-inscription_wrap').length) {
                //     $('.b-inscription_wrap').html(response.content);
                // }
                // module.call('scrollTo', '.b-inscription_wrap', 'Scroll');
            },
        });
    }

    calcul() {
        let form = $("#adhesion_form");

        let nbNuits = Number($('input[name="nuits[]"]').serializeArray().length);
        let nbNuitsWe = Number($('#hebergement_we').val() == "oui");
        let inscriptionWe = Number($('#inscription_we').val() == "oui");

        $.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: {
                'action': 'calcul_cout_inscription_ag',
                'inscriptionAg': Number(true),
                'inscriptionWe': inscriptionWe,
                'nombreNuitsAg': nbNuits,
                'nombreNuitsWe': nbNuitsWe,
            },
            success: function (response) {
                $('#total')[0].innerText = response;
            },
        });
    }
}

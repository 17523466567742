import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            change: {
                toggleDisplay: 'toggleDisplay'
            }
        }

        this.select = $(this.el.dataset.conditionalSource)[0];
        this.target = $(this.el.dataset.conditionalTarget)[0];
        this.displayValue = this.el.dataset.displayValue;
    }

    init() {
        if (!this.select || !this.target) {
            console.error('Select or target element not found');
            return;
        }
        this.toggleDisplay();
    }

    toggleDisplay() {
        if (this.select.value === this.displayValue) {
            this.target.style.display = 'block';
            
        } else {
            this.target.style.display = 'none';
            $(this.target).find('input').prop('checked', false);
            $(this.target).find('input').removeClass('checked');
        }
        this.call('calcul', false, 'Inscription');
    }
}